import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Indicador } from './models/indicador';
import { catchError, map, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
//import { url } from 'inspector';
//import { stringify } from '@angular/core/src/render3/util';
import { Rotulo } from './models/rotulo';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class IndicadorService {

  constructor(private http: HttpClient, private alertService: AlertService) { }

  getIndicadores(rotulo?: string): Observable<Indicador[]> {
    var url;
    if(rotulo != null){
      console.log("Buscando por rotulo...");
      url = 'https://thstecnologia.com.br/api-bi/public/indicadores/'+ rotulo;
    } else {
      url = 'https://thstecnologia.com.br/api-bi/public/indicadores';
    }
    return this.http.get<Indicador[]>(url)
        .pipe(
          tap(_ => console.log("Fetched Indicators")),
          catchError(this.handleError('getIndicadores', []))
        );
  }

  getRotulos(): Observable<Rotulo[]> {
    var url = 'https://thstecnologia.com.br/api-bi/public/rotulos';

    return this.http.get<Rotulo[]>(url)
        .pipe(
          tap(_ => console.log("Fetched Rotules")),
          catchError(this.handleError('getRotulos', []))
        );
  }

  getIndicador(id: number): Observable<Indicador> {
    const url = 'https://thstecnologia.com.br/api-bi/public/indicador/'+id;
    return this.http.get<Indicador>(url).pipe(
      tap(_ => console.log(`Fetched Indicator id=${id}`)),
      catchError(this.handleError<Indicador>(`getIndicador id=${id}`))
    );
  }

  // Descontinuado após implementação do Firebase
  /*criarIndicador(indicador: Indicador): Observable<Indicador> {
    var data = new Date();
    indicador.DTCADASTRO = data.getFullYear().toString() + "-" + data.getMonth().toString() + "-" + data.getDate().toString();
    if(indicador.TIPO == 'M') { indicador.QUERY = ""; } else { indicador.VALOR = 0; }
    //console.log("Indicador: " + indicador.ID + " " + indicador.DESCRICAO + " " + indicador.DTCADASTRO + " " + indicador.QUERY + " " + indicador.TIPO + " " + indicador.VALOR + " " + indicador.VISIVEL + " " + indicador.PERCALERTA + " " + indicador.META);
    const url = 'https://thstecnologia.com.br/api-bi/public/indicadores';
    return this.http.post<Indicador>(url, indicador, httpOptions).pipe(
      tap((indicador: Indicador) => console.log(`added indicator w/ id=${indicador.ID}`)),
      catchError(this.handleError<Indicador>('addIndicator'))
    );
  }

  atualizarIndicador(indicador: Indicador): Observable<Indicador> {
    const url = 'https://thstecnologia.com.br/api-bi/public/indicadores';

    return this.http.put<Indicador>(url, indicador, httpOptions).pipe(
      catchError(this.handleError<Indicador>('updateIndicator'))
    );
  }*/

  removerIndicador(id: number): Observable<{}> {
    const url = `https://thstecnologia.com.br/api-bi/public/indicadores/${id}`;
    return this.http.delete(url, httpOptions).pipe(
      catchError(this.handleError('deleteIndicator'))
    );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      this.alertService.error(`Ops... Ocorreu um erro: ${error.message}` );
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
 
}
