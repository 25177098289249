import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
//import { Session } from 'protractor';
//import { User } from './models/user';
//import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserLogin } from './models/userLogin';
//import { catchError, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from './models/user';
//import { userInfo } from 'os';

/*const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};*/

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  private userName = new BehaviorSubject<string>('Desconectado');
  //private idEmpresa = new BehaviorSubject<string>('');
  //private userInfo: User = new User();

  get isLoggedIn() {
    if(sessionStorage.getItem('usernameBI')){
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
    //console.log('Teste 2: '+this.loggedIn.value);
    return this.loggedIn.asObservable(); // {2}
  }

  get loggedUserName() {
    if(sessionStorage.getItem('userName')){
      this.userName.next('Olá, '+sessionStorage.getItem('userName')+'!');
    } else {
      this.userName.next('Desconectado');
    }
    //console.log('Teste 2: '+this.loggedIn.value);
    return this.userName.asObservable(); // {2}
  }

  constructor(
    private router: Router,
    //private http: HttpClient,
    private alertService: AlertService,
    public auth: AngularFireAuth,
    private afStore: AngularFirestore
  ) {}

  /*login(user){
    if (user.USERNAME !== '' && user.PASSWORD !== '' ) { // {3}
      sessionStorage.setItem("usernameBI", user.USERNAME); // Armazena usuário na Session
      this.loggedIn.next(true);
      this.router.navigate(['/']);
    }
  }*/

  login(userLogin: UserLogin): void {
    /*const url = 'https://thstecnologia.com.br/api-bi/public/login';
    this.http.post<User>(url, userLogin, httpOptions).pipe(
      tap(_ => console.log(`Fetched user login login=${userLogin.USERNAME}`)),
      catchError(this.handleError<User>(`doLogin failed login=${userLogin.USERNAME}`))
    ).subscribe(
      user => {
        if(user.LOGIN == userLogin.USERNAME){
          sessionStorage.setItem("usernameBI", user.LOGIN); // Armazena usuário na Session
          this.router.navigate(['/dashboard']);
        } else {
          this.alertService.error("Usuário ou senha incorretos");
        }
      }
    )*/
    this.auth.auth.signInWithEmailAndPassword(userLogin.USERNAME, userLogin.PASSWORD).then(
      (user) => {
        if(user != null){
          sessionStorage.setItem("usernameBI", user.user.email); // Armazena usuário na Session
          // Obtém as informações do usuário logado e armazena na session
          //this.getUserInfo(user.user.email);
          this.afStore.collection('usuarios').doc<User>(user.user.email).valueChanges().subscribe(
            (user) => {
              //userInfo = user.data;
              if(user != null){
                sessionStorage.setItem('userEmpresaId', user.id_empresa);
                sessionStorage.setItem('userName', user.nome);
                this.userName.next('Olá, '+sessionStorage.getItem('userName')+'!');
                this.router.navigate(['/dashboard']);
              } else {
                this.alertService.error('Ops... Ocorreu um erro ao localizar o usuário. Contate o administrador do Sistema');
              }
            }
          )          
          //this.router.navigate(['/dashboard']);
        } else {
          alert("Usuário sem dados");
        }        
      }
    ).catch((error) => {
      var errorCode = error.code
      //this.alertService.error("Ops... Falha no Login: "+errorCode);
      this.alertService.error("Usuário ou senha incorretos");
    })
  }

  // Buscar as informações do usuário na coleção USUARIOS e Salvar na SessionStorage
  /*getUserInfo(email: string){
    //userInfo: User;
    //alert("Email: "+email);
    this.afStore.collection('usuarios').doc<User>(email).valueChanges().subscribe(
      (user) => {
        //userInfo = user.data;
        if(user != null){
          alert("ID_EMPRESA_USER: "+user.id_empresa);
          sessionStorage.setItem('userEmpresaId', user.id_empresa);
          sessionStorage.setItem('userName', user.nome);
        } else {
          this.alertService.error('Ops... Ocorreu um erro ao localizar o usuário. Contate o administrador do Sistema');
        }
      }
    )
    
  }*/

  getIdEmpresa(): string {
    //alert("Empresa ID: " + sessionStorage.getItem('userEmpresaId'))
    return sessionStorage.getItem('userEmpresaId');
  }

  logout() {     
    sessionStorage.removeItem('usernameBI'); 
    sessionStorage.removeItem('userName'); 
    sessionStorage.removeItem('userEmpresaId'); 
    this.auth.auth.signOut(); // Faz logoff no FirebaseAuth                      // {4}
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      this.alertService.error(`Ops... Ocorreu um erro: ${error.message}` );
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
