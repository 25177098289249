import { Component, OnInit } from '@angular/core';
import { IndicadorService } from '../indicador.service';
import { Indicador } from '../models/indicador';
import { AlertService } from '../alert.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../auth.service';
import { DialogConfirmService } from '../dialog-confirm.service';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.css']
})
export class IndicadoresComponent implements OnInit {
  indicadores: Observable<Indicador[]>;

  constructor(private dialogConfirmService: DialogConfirmService, private authService: AuthService, private afStore: AngularFirestore, private indicadorService: IndicadorService, private alertService: AlertService, private router: Router) { }

  ngOnInit() {
    this.loadIndicadores();
  }

  loadIndicadores(): void {
    var idEmpresa = this.authService.getIdEmpresa();
    if(idEmpresa == null){
      this.alertService.error('Ops... O ID da Empresa não foi localizado. Faça o Login novamente, por favor.');
      return;
    } else {
      this.indicadores = this.afStore.collection('empresas').doc(idEmpresa).collection<Indicador>('INDICADORES',  ref => ref.orderBy('ROTULO')).valueChanges();
    }
    /*this.indicadorService.getIndicadores().subscribe(
      indicadores => {
        this.indicadores = indicadores;
      }
    );*/
  }

  removerIndicador(ind: Indicador){
    var idEmpresa = this.authService.getIdEmpresa();
    if(idEmpresa == null){
      this.alertService.error('Ops... O ID da Empresa não foi localizado. Faça o Login novamente, por favor.');
      return;
    } else {
      // Confirmação de exclusão
      this.dialogConfirmService.confirm("Deseja realmente excluir o Indicador " + ind.DESCRICAO + "?").then(
        (podeDeletar: boolean) => {
          if(podeDeletar){
            this.afStore.collection('empresas').doc(idEmpresa).collection<Indicador>('INDICADORES').doc<Indicador>(ind.ID).delete().then(
              (indicador) => this.alertService.success("Indicador removido com sucesso!")
            ).catch((error) => {
              this.alertService.error("Ops... Ocorreu um erro ao remover o indicador: "+error);
            })
          }
        }
      );
      
    }
    /*this.indicadorService.removerIndicador(id).subscribe(
      status => {
        this.validarExclusao(status);
      }
    )*/
  }

  validarExclusao(status): void {
    if(status == true){
      this.alertService.success('Indicador excluído com sucesso.');
      this.loadIndicadores();
    } else {
      this.alertService.error('Falha ao excluir o indicador. Tente novamente ou entre em contato com o suporte.');
    }
  }

}
