import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IndicadoresComponent } from './indicadores/indicadores.component';
import { CadastroIndicadorComponent } from './cadastro-indicador/cadastro-indicador.component';
import { LoginComponent } from './login/login.component';
import { LoginguardService } from './loginguard.service';

const routes: Routes = [
  {path:'', component: DashboardComponent, canActivate: [LoginguardService]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [LoginguardService]},
  {path: 'indicadores', component: IndicadoresComponent, canActivate: [LoginguardService]},
  {path: 'indicadores/cadastro/:id', component: CadastroIndicadorComponent, canActivate: [LoginguardService]},
  {path: 'indicadores/cadastro/novo', component: CadastroIndicadorComponent, canActivate: [LoginguardService]},
  {path: 'login', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
