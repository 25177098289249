import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogConfirmService {

  constructor() { }

  confirm(message? : string) 
    {
        return new Promise(resolve => {
            return resolve(window.confirm(message || 'Confirma?'));
        });
    }
}
