import { Component, OnInit, Input } from '@angular/core';
import { Indicador } from '../models/indicador';
import { ActivatedRoute, Router } from '@angular/router';
import { IndicadorService } from '../indicador.service';
import { AlertService } from '../alert.service';
import { IfStmt } from '@angular/compiler';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-cadastro-indicador',
  templateUrl: './cadastro-indicador.component.html',
  styleUrls: ['./cadastro-indicador.component.css']
})
export class CadastroIndicadorComponent implements OnInit {
  indicador: Indicador;
  //idEmpresa: string;

  constructor(private auth: AuthService, private afStore: AngularFirestore, private route: ActivatedRoute, private indicadorService: IndicadorService, private alertService: AlertService, private router: Router) { 
    this.indicador = new Indicador();
  }

  ngOnInit() {
    this.getIndicador();
    //this.idEmpresa = this.auth.getIdEmpresa(); 
  }

  getIndicador(): void{
    const id: string = String(this.route.snapshot.paramMap.get('id'));
    console.log("id: "+id);
    /*if(id > 0){
      this.indicadorService.getIndicador(id).subscribe(
        indicador => this.indicador = indicador
      );      
      //console.log("Id ind: "+this.indicador.ID);
    } else {
      this.indicador = new Indicador();
    }*/

    // Alteração para buscar os dados no Firebase
    // Caso o ID da empresa esteja nulo, exibe mensagem de erro e para a execução
    var idEmpresa = this.auth.getIdEmpresa();
    if(idEmpresa == null){
      //alert(idEmpresa);
      this.alertService.error('Ops... Id da emprsa não encontrado. Faço login novamente, por favor.');
      return;
    }

    if(id != null && id != 'novo'){
      this.afStore.collection('empresas').doc(idEmpresa).collection('INDICADORES').doc<Indicador>(id).valueChanges().subscribe(
        (indicador) => {
          if(indicador != null){
            this.indicador = indicador;
          } else {
            this.alertService.error('Ops... Indicador não localizado.');
          }
          
        },
        (error) => this.alertService.error('Ops... Ocorreu um erro ao tentar carregar o indicador. Tente novamente')
      );
    } else {
      this.indicador = new Indicador();
    }

  }

  onSubmit(){
    // Update
    /*console.log("Tipo do indicador: "+this.indicador.TIPO);
    if(this.indicador.ID != null){      
      this.indicadorService.atualizarIndicador(this.indicador).subscribe(
        status => this.validarAtualizacao(status),
        error => console.log("Erro ao atualizar indicador: "+error)
      );
    } else { // Insert
      this.indicadorService.criarIndicador(this.indicador).subscribe(
        id => this.sucesso(id),
        error => console.log("Erro ao cadastrar indicador: "+error)
      );
    }*/

    // Validar se o ID_EMPRESA existe na Session
    if(this.auth.getIdEmpresa() == null){
      //alert(idEmpresa);
      this.alertService.error('Ops... Id da emprsa não encontrado. Faço login novamente, por favor.');
      return;
    }

    // Update
    if(this.indicador.ID == null){
      // Create (Cria um indicador e obtem o ID antes de armazenar os dados)
      this.afStore.collection('empresas/'+this.auth.getIdEmpresa()+'/INDICADORES').add(JSON.parse(JSON.stringify(this.indicador))).then(
        ref => {
          var data = new Date();
          this.indicador.ID = ref.id;
          this.indicador.DTCADASTRO = data.getFullYear().toString() + "-" + data.getMonth().toString() + "-" + data.getDate().toString();
          //Chama atualização do indicador
          this.atualizarIndicador();
        }
      ).catch((error) => this.alertService.error(error));
    } else {
      //Chama atualização do indicador direto (caso seja apenas um UPDATE)
      this.atualizarIndicador();
    }
  }

  atualizarIndicador(): void{
    this.afStore.collection('empresas').doc(this.auth.getIdEmpresa()).collection('INDICADORES').doc<Indicador>(this.indicador.ID).update(JSON.parse(JSON.stringify(this.indicador))).then(
      indicador => {
        this.alertService.success('Indicador atualizado com sucesso!');
        this.router.navigate(['indicadores']);
      }
    ).catch((error) => this.alertService.error(error));
  }

  // Descontinuado após a implementação do Firebase
  /*sucesso(id): void{
    if(id > 0) {
      this.alertService.success("Indicador cadastrado com Sucesso!");
      this.router.navigate(['dashboard']);
    }
  }

  validarAtualizacao(status): void {
    if(status == true){
      this.alertService.success("Indicador atualizado com Sucesso!");
      this.router.navigate(['dashboard']);
    } else {
      this.alertService.error('Falha ao atualizar o indicador. Tente novamente ou entre em contato com o suporte.');
    }
  }*/

}
