import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserLogin } from '../models/userLogin';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: UserLogin;

  constructor(private authService: AuthService) { 

  }

  ngOnInit() {
    this.user = new UserLogin();
  }

  onSubmit(){
    this.authService.login(this.user);
  }

}
