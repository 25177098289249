import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Indicador} from '../models/indicador';
import { IndicadorService } from '../indicador.service';
import { Rotulo } from '../models/rotulo';
import { AuthService } from '../auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  indicadores: Observable<Indicador[]>;

  rotulos: Rotulo[];

  selectedRotulo = "";

  testeCheck = true;

  constructor(private authService: AuthService, private afStore: AngularFirestore) { }

  ngOnInit() {
    //this.carregarRotulos();
    this.carregarIndicadores();
  }

  // validar a cor de acordo com o tipo de escala
  validarEscala(ind: Indicador): string{
    var cor = '';
    //console.log('Ind escala: '+ind.ID + " " + ind.INVERTERESCALA);
    if(ind.INVERTERESCALA == true){
      //console.log('Ind escala: '+ind.ID + " " + ind.INVERTERESCALA);
      cor = (parseFloat(ind.VALOR) > parseFloat(ind.META)) ? '#FFA07A' : '#40E0D0';
    } else {
      //console.log('Ind escala teste: '+ind.ID + " " + ind.INVERTERESCALA);
      /*if( parseFloat(ind.VALOR) < parseFloat(ind.META) ){
        console.log('Ind vl. menor que meta: '+ind.ID + " " + ind.INVERTERESCALA + " Valor: " + ind.VALOR + " Meta: " + ind.META);
      } else {
        console.log('Ind vl. maior que meta: '+ind.ID + " " + ind.INVERTERESCALA + " Valor: " + ind.VALOR + " Meta: " + ind.META);
      }*/
      cor = (parseFloat(ind.VALOR) < parseFloat(ind.META)) ? '#FFA07A' : '#40E0D0';
    }
    return cor;
  }

  carregarIndicadores(rotulo?: string){
    /*console.log("Rotulo: " + rotulo);
    if(rotulo != null){
      this.selectedRotulo = rotulo;
      console.log("Rotulo selecionado: " + this.selectedRotulo);
    } else {
      this.selectedRotulo = 'TODOS';
    }
    this.indicadorService.getIndicadores(rotulo).subscribe(
      indicadores => {
        this.indicadores = indicadores;
        console.log(this.indicadores);
      });

      //console.log("Teste valor: " + (155555.99).toLocaleString('pt-BR'));*/
    var idEmpresa = this.authService.getIdEmpresa();
    /*var cont = 0;

    // Executa aguardando o retorno do ID_EMPRESA, por no máximo 10 segundos
    while(this.authService.getIdEmpresa() == null){
      cont = cont + 1;
    }*/

    if(idEmpresa == null){
      alert("Erro ao obter o ID_EMPRESA. Verifique a sua internet e faça login novamente.");
      this.authService.logout();
      return;
    }
    if(this.selectedRotulo == ""){
      this.indicadores = this.afStore.collection('empresas').doc(idEmpresa).collection<Indicador>('INDICADORES', ref => ref.where("VISIVEL", "==", true).orderBy('ROTULO')).valueChanges();
    } else {
      this.indicadores = this.afStore.collection('empresas').doc(idEmpresa).collection<Indicador>('INDICADORES', ref => ref.where("ROTULO", "==", this.selectedRotulo).where("VISIVEL", "==", true)).valueChanges();
    }
  }

  // Desabilitado após implementação do Firebase
  /*carregarRotulos(){
    this.indicadorService.getRotulos().subscribe(
      rotulos => {
        this.rotulos = rotulos;
        console.log(this.rotulos);
      });
  }*/

  getActiveTab(){
    return this.selectedRotulo;
  }

  onSubmitFilter(){
    this.carregarIndicadores(this.selectedRotulo);
  }

  // Função para obter o valor do DAshboard formatado: incluído em 09/11/2019
  getValorDash(valor){
    //valor = valor.toFixed(2);
    var valorFloat = parseFloat(valor);
    var valorRetorno;

    if(valorFloat < 1000){
      valorRetorno = valorFloat.toLocaleString('pt-BR', {currency: 'BRL', maximumFractionDigits: 2});
    }
    if(valorFloat >= 1000 && valorFloat < 1000000){
      valorRetorno = (valorFloat / 1000).toLocaleString('pt-BR', {currency: 'BRL', maximumFractionDigits: 2}) + 'K';
    }
    if(valorFloat >= 1000000){
      valorRetorno = (valorFloat / 1000000).toLocaleString('pt-BR', {currency: 'BRL', maximumFractionDigits: 2}) + 'M';
    }
    
    return valorRetorno;
  }

}
