export class Indicador {
    ID: string;
    DESCRICAO: String;
    TIPO: String;
    DTCADASTRO: string;
    VISIVEL: Boolean;
    QUERY: String;
    VALOR: any;
    META: any;
    PERCALERTA: number;
    DIASPESQUISA: String;
    INVERTERESCALA: Boolean;
    ROTULO: string;
    PREFIXO: string;
    SUFIXO: string;
    DTULTIMAATUALIZACAO: string;
}