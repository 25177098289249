import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;                  // {1}

  userNameBi = new BehaviorSubject<string>('');

  constructor(private authService: AuthService, private route: Router) {
    
   }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn; // {2}

    this.authService.loggedUserName.subscribe(
      (name) => this.userNameBi.next(name.toString())
    );

  }

  onLogout(){
    this.authService.logout();                      // {3}
  }

  getActiveRoute(): string{
    return this.route.url;
  }

}
